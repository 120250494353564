import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { LoadingButton } from "@mui/lab";
import AddressDetail from "../../../CreateOrder/addressDetail";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  //errorToast,
  handleError,
  isArrayEqual,
  parseTimeslotToDateObj,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import OrderDetail from "../../../CreateOrder/orderDetail";
import EditConsultationOrder from "../../../../pages/Consultation/Edit";
import EditDiagnosticsOrder from "../../../../pages/Diagnostics/Edit";
import EditMedicinesOrder from "../../../../pages/Pharmacy/Edit";
import {
  CONSULTATION_TYPE,
  ORDER_STATUS,
  ORDER_TYPE,
} from "../../../../enums/order.enum";
import { format, isEqual, parse } from "date-fns";
import { editConsultationSchema } from "../../../../schemas/consultation";
import { editDiagnosticSchema } from "../../../../schemas/diagnostic";
import { editPharmacySchema } from "../../../../schemas/pharmacy";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import SelfOptNotes from "../../../../pages/Pharmacy/Edit/selfOptNotes";

const EditOrderModal = ({
  editOrderModal,
  setEditOrderModal,
  orderData,
  setParentModal,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [changedClinic, setChangedClinic] = useState<boolean>(false);
  const [selectedClinics, setSelectedClinics] = useState<any>(
    orderData?.order_type === ORDER_TYPE.CONSULTATION
      ? new Array(orderData?.merchant_details)
      : []
  );
  const [nearbyClinicList, setNearbyClinicList] = useState<any>([]);
  const [changeType, setChangeType] = useState("");
  const [actionType, setActionType] = useState("update");
  const [selectedTests, setSelectedTests] = useState<any>(
    orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
      ? [...orderData?.order_details?.items]
      : []
  );
  const [testsTableError, setTestsTableError] = React.useState<any>([]);
  const nearbyPharmacyList =
    orderData?.order_type === ORDER_TYPE.MEDICINES
      ? [...orderData?.nearby_merchants]
      : [];

  const addMedType = (val: any) => {
    return val
      ? val.map((x: any) => {
          return { ...x, med_from_db: "true" };
        })
      : [];
  };

  const [selectedPharmacy, setSelectedPharmacy] = useState<any>({});
  const [selectedMedicines, setSelectedMedicines] = useState<any>(
    orderData?.order_type === ORDER_TYPE.MEDICINES
      ? addMedType([...orderData?.order_details?.items])
      : []
  );
  const [selectedMedicinesId, setSelectedMedicinesId] = useState<any>([]);
  const [medTableError, setMedTableError] = useState<any>([]);
  const [providerFillData, setProviderFillData] = useState<any>(null);

  //function to update change type of medicines before submit
  const updateChangeType = (oldMedicines: any, newMedicines: any) => {
    let comparedMedicines: any = [];

    newMedicines.forEach((medicine: any) => {
      if (
        ["alternative", "new", "not_available", "available"].includes(
          medicine.change_type
        )
      ) {
        if (medicine.db_mrp) delete medicine.db_mrp;
        if (medicine.alt_from_pharmacy) delete medicine.alt_from_pharmacy;
        //if (medicine.med_from_db) delete medicine.med_from_db;
        comparedMedicines.push(medicine);
      } else {
        for (let i = 0; i < oldMedicines.length; i++) {
          let old = oldMedicines[i];
          const uniqueKey = old.sku_id ? "sku_id" : "item";
          if (medicine[uniqueKey] === old[uniqueKey]) {
            let changes = [];

            if (Number(medicine.quantity) !== Number(old.quantity)) {
              changes.push("quantity");
            }

            if (Number(medicine.MRP) !== Number(old.MRP)) {
              changes.push("price");
            }

            if (changes.length !== 0) {
              medicine.requested_item = old;
              medicine.change_type = changes.join("_");
              comparedMedicines.push(medicine);
            } else {
              comparedMedicines.push(medicine);
            }
            break;
          }
        }
      }
    });

    comparedMedicines.forEach((medicine: any) => {
      medicine.gst = isNaN(Number(medicine.gst)) ? 0 : Number(medicine.gst);
    });

    return comparedMedicines;
  };

  useEffect(() => {
    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      if (
        orderData.nearby_merchants &&
        orderData.nearby_merchants[0] &&
        orderData.nearby_merchants[0].process_mode?.fill_mrp?.toLowerCase() ===
          "y"
      ) {
        if (orderData.provider_assigned_at) {
          let orderAssigned = parse(
            orderData.provider_assigned_at.split(".")[0],
            "dd/MM/yyyy HH:mm:ss",
            new Date()
          );
          let date = new Date();
          var diff = (date.getTime() - orderAssigned.getTime()) / 1000;
          diff /= 60;
          diff = Math.abs(Math.round(diff));
          if (diff > 15) {
            setProviderFillData("false");
          } else {
            if (
              orderData.merchant_updated_meta &&
              orderData.merchant_updated_meta[0]?.items?.merchant_filled
            ) {
              setProviderFillData("false");
            } else {
              setProviderFillData("true");
            }
          }
        } else {
          setProviderFillData("false");
        }
      } else {
        setProviderFillData("false");
      }
    }
  }, [orderData]);

  useEffect(() => {
    let t: Array<string> = [];
    selectedMedicines.map((x: any) => t.push(x.sku_id));
    setSelectedMedicinesId(t);
  }, [selectedMedicines]);

  useEffect(() => {
    if (changeType !== "") {
      setActionType("change");
    } else {
      setActionType("update");
    }
  }, [changeType]);

  const handleSubmit = async (values: any, actions: any) => {
    let updatePayload: any = {
      order_id: orderData?.order_id,
      type:
        actionType === "update"
          ? `${orderData?.order_type + "-profile-update"}`
          : `${
              orderData?.order_type === ORDER_TYPE.MEDICINES
                ? "order"
                : orderData?.order_type
            }-modification-request`,
    };

    if (orderData?.order_type === ORDER_TYPE.CONSULTATION) {
      if (selectedClinics.length === 0) {
        alert("Please select a clinic to proceed");
        return;
      }
      delete selectedClinics[0]?.status;

      if (actionType === "change") {
        updatePayload = {
          ...updatePayload,
          provider_payable: values.final_payable?.toString(),
          discount_percentage: values.discount,
        };
      }

      updatePayload = {
        ...updatePayload,
        time_slot: format(values.time_slot, "dd-MM-yyyy, hh:mm a"),
        partner_consultation_charges: values.partner_charges?.toString(),
        merchant_details: {
          ...selectedClinics[0],
          change_type: changeType,
          profile_details: {
            name: values.doctor_name,
            full_name: values.doctor_name,
            gender: values.doctor_gender,
            experience_in_years: values.doctor_exp_yrs,
            consultation_fees: values.doctor_fee,
            languages: "English",
            whatsapp_number: values.doctor_mobile,
            degrees: values.doctor_degrees,
            clinic_name: selectedClinics?.[0]?.clinic_name,
            provider_type: selectedClinics[0]?.merchant_type,
            specialty: values.speciality,
          },
        },
      };
    }

    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      let formError = false;
      //meds table error alert
      medTableError.forEach((x: any) => {
        if (x.mrp || x.pack_quantity || x.quantity) {
          formError = true;
        }
      });

      if (formError) {
        alert("Please correct all errors to proceed");
        return;
      }

      let updatedMedicines: any = updateChangeType(
        orderData?.order_details?.items,
        selectedMedicines
      );

      updatePayload = {
        ...updatePayload,
        location_id: selectedPharmacy?.location_id,
        data: [
          {
            discount_percentage: values.discount,
            provider_delivery_charges:
              values.delivery_partner === "provider_delivery"
                ? values.delivery_charges
                : "",
            merchant_details: {
              ...selectedPharmacy,
              mobile: values.merchant_mobile,
              gst_no: values.gst_no,
              gst_name: values.company,
              delivery_partner: values.delivery_partner,
            },
            order_details: {
              doctor: values.doctor,
              prescription_links:
                orderData?.order_details?.prescription_links == null
                  ? []
                  : orderData?.order_details?.prescription_links,
              items: updatedMedicines,
            },
          },
        ],
      };
    }

    if (orderData?.order_type === ORDER_TYPE.DIAGNOSTICS) {
      let formErrorTests = false;
      //tests table error alert
      testsTableError.forEach((x: any) => {
        if (x.MRP) {
          formErrorTests = true;
        }
      });

      if (formErrorTests) {
        alert("Please correct all errors to proceed");
        return;
      }

      delete selectedTests?.status;
      if (actionType === "change") {
        updatePayload = {
          ...updatePayload,
          change_type: changeType,
          formatted_address: orderData?.merchant_details?.formatted_address,
        };
      }
      updatePayload = {
        ...updatePayload,
        discount_percentage: values?.discount,
        time_slot: format(values.time_slot, "dd-MM-yyyy, hh:mm a"),
        merchant_details: {
          ...orderData?.merchant_details,
          profile_details: {
            name: values.lab_name,
            whatsapp_number: values.lab_mobile,
          },
        },
        order_details: {
          items: selectedTests,
        },
      };
    }

    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      if (orderData?.order_status === ORDER_STATUS.PAYMENT_COLLECTED) {
        handleInvoice(values);
        return;
      } else {
        if (actionType === "update") {
          handleAcceptOrder(values);
          return;
        }
      }
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        updatePayload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (actionType === "update") {
          handleAcceptOrder(values);
        } else {
          setLoading(false);
          successToast("submitted for confirmation");
          setParentModal(false);
          //update
          dispatch(
            setAllOrderData(
              transformAllOrder(
                ordersToManage,
                updatePayload?.order_id,
                result.data.response
              )
            )
          );
        }
      } else {
        setLoading(false);
        //errorToast(result.data.message);
        throw result.data;
      }
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  const handleAcceptOrder = async (values: any) => {
    if (orderData.order_type === ORDER_TYPE.MEDICINES) {
      let isUnmappedSkuPresent = false;
      for (let i = 0; i < selectedMedicines.length; i++) {
        if (
          !selectedMedicines[i].sku_id &&
          selectedMedicines[i].change_type !== "not_available"
        ) {
          isUnmappedSkuPresent = true;
          errorToast("Please select an alternative for unmapped SKU(s)");
          break;
        }
      }
      if (isUnmappedSkuPresent) {
        return;
      }
    }
    let acceptPayload: any = {
      order_id: orderData?.order_id,
      location_id:
        orderData?.order_type === ORDER_TYPE.CONSULTATION
          ? selectedClinics[0]?.location_id
          : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
          ? orderData?.merchant_details?.location_id
          : selectedPharmacy?.location_id,
      acceptance_type: "accepted",
    };
    if (orderData.order_type === ORDER_TYPE.MEDICINES) {
      acceptPayload = {
        ...acceptPayload,
        merchant_details: {
          ...selectedPharmacy,
          gst_no: values.gst_no,
          pan_no: "0",
          order_availability: "complete",
          delivery_partner: values.delivery_partner,
          mobile: values.merchant_mobile,
        },
      };
    }
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/accept-order`,
        acceptPayload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        handleInvoice(values);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              acceptPayload?.order_id,
              result.data.response
            )
          )
        );
      } else {
        setLoading(false);
        //errorToast(result.data.message);
        throw result.data;
      }
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  const handleInvoice = async (formValues: any) => {
    let invoicePayload: any = {
      order_id: orderData?.order_id,
    };

    if (orderData?.order_type === ORDER_TYPE.CONSULTATION) {
      invoicePayload = {
        ...invoicePayload,
        invoice_no: "",
        invoice_url: "",
        discount_percentage: formValues.discount,
        provider_payable: formValues.final_payable?.toString(),
        consultation_type: formValues.consultation_type,
        merchant_details: {
          ...orderData.merchant_details,
          order_availability: "complete",
          gst_no: "",
          pan_no: "",
          profile_details: {
            name: formValues.doctor_name,
            full_name: formValues.doctor_name,
            gender: formValues.doctor_gender,
            experience_in_years: formValues.doctor_exp_yrs,
            consultation_fees: formValues.doctor_fee,
            whatsapp_number: formValues.whatsapp_number,
            languages: "English",
            degrees: formValues.doctor_degrees,
            provider_type: "clinic",
            specialty: orderData.specialty,
          },
        },
        order_details: {
          items: [
            {
              item: "Consultation",
              details: orderData.consultation_type,
              MRP: formValues.doctor_fee.toString(),
            },
          ],
          doctor: formValues.doctor_name,
        },
      };
      if (orderData?.consultation_type === CONSULTATION_TYPE.ONLINE) {
        invoicePayload = {
          ...invoicePayload,
          partner_consultation_charges: formValues.partner_charges?.toString(),
        };
      }
    }

    if (orderData?.order_type === ORDER_TYPE.DIAGNOSTICS) {
      invoicePayload = {
        ...orderData,
        ...invoicePayload,
        invoice_no: "",
        invoice_url: "",
        discount_percentage: String(formValues.discount),
        merchant_details: {
          ...orderData?.merchant_details,
          order_availability: "complete",
          gst_no: "0",
          pan_no: "0",
          profile_details: {
            name: formValues.lab_name,
            whatsapp_number: formValues.lab_mobile,
          },
        },
        order_details: {
          items: selectedTests,
          doctor: "",
        },
      };
    }

    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      let updatedMedicines: any = updateChangeType(
        orderData?.order_details?.items,
        selectedMedicines
      );

      invoicePayload = {
        ...invoicePayload,
        invoice_no: formValues.invoice_no,
        discount_percentage: formValues.discount,
        provider_delivery_charges:
          formValues.delivery_partner === "provider_delivery"
            ? formValues.provider_delivery_charges
            : "",
        merchant_details: {
          ...selectedPharmacy,
          gst_no: formValues.gst_no,
          pan_no: "0",
          order_availability: "complete",
          delivery_partner: formValues.delivery_partner,
          mobile: formValues.merchant_mobile,
        },
        order_details: {
          items: updatedMedicines.map((x: any) => ({
            ...x,
            gst: Number(x.gst),
          })),
          doctor: formValues.prescribed_by,
        },
      };

      //invoice revision
      if (orderData?.order_status === ORDER_STATUS.PAYMENT_COLLECTED) {
        invoicePayload = {
          ...invoicePayload,
          invoice_revision: true,
        };
      }
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/invoices/create-invoice`,
        invoicePayload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("invoice created successfully");
        setParentModal(false);
        // cal API for MEDICINE EXPRESS DELIVERY UPDATION after 3sec
        if (
          orderData?.order_type === ORDER_TYPE.MEDICINES &&
          orderData.express_delivery === "y"
        ) {
          setTimeout(async () => {
            const singleResult = await privateInstance.get(
              `${API_VERSION.V1}/orders/partner/${orderData?.order_partner}/${orderData?.partner_order_id}`
            );
            if (singleResult?.data?.status === API_RESULT.SUCCESS) {
              //update
              dispatch(
                setAllOrderData(
                  transformAllOrder(
                    ordersToManage,
                    invoicePayload?.order_id,
                    singleResult.data.response
                  )
                )
              );
            }
          }, 3000);
        } else {
          //update
          dispatch(
            setAllOrderData(
              transformAllOrder(
                ordersToManage,
                invoicePayload?.order_id,
                result.data.response
              )
            )
          );
        }
      } else {
        //errorToast(result.data.message);
        throw result.data;
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const FormObserver: React.FC = () => {
    const { values }: any = useFormikContext();
    useEffect(() => {
      //console.log("FormValues", values);
      if (orderData?.order_type === ORDER_TYPE.CONSULTATION) {
        setChangeType("");
        if (
          changedClinic &&
          orderData?.merchant_details?.name?.toLowerCase() !==
            selectedClinics[0]?.clinic_name?.toLowerCase()
        ) {
          setChangeType("reassigned");
        }
        if (
          !isEqual(
            parseTimeslotToDateObj(orderData?.merchant_details?.time_slot),
            new Date(values?.time_slot)
          )
        ) {
          setChangeType("rescheduled");
        }
        if (
          !isEqual(
            parseTimeslotToDateObj(orderData?.merchant_details?.time_slot),
            new Date(values?.time_slot)
          ) &&
          changedClinic &&
          orderData?.merchant_details?.name?.toLowerCase() !==
            selectedClinics[0]?.clinic_name?.toLowerCase()
        ) {
          setChangeType("rescheduled_and_reassigned");
        }
        // PARTNER SPECIFIC: LOMBARD
        if (orderData?.order_partner === "lombard") {
          setChangeType("");
        }
        //REASSIGN CONS: after booking confirm
        if (orderData?.order_status === ORDER_STATUS.BOOKING_CONFIRMED) {
          setChangeType("reassigned");
        }
      }

      if (orderData?.order_type === ORDER_TYPE.DIAGNOSTICS) {
        setChangeType("");
        if (
          !isEqual(
            parseTimeslotToDateObj(orderData?.merchant_details?.time_slot),
            new Date(values?.time_slot)
          )
        ) {
          setChangeType("rescheduled");
        }
        if (orderData?.order_status === ORDER_STATUS.PARTNER_PENDING) {
          setChangeType("partner_pending");
        }
        // if (!isArrayEqual(orderData?.order_details?.items, selectedTests)) {
        //   setChangeType("price_change");
        // }
      }

      if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
        setChangeType("");
        if (orderData?.partner_order_status === ORDER_STATUS.PENDING) {
          setChangeType("pending");
        }
        //delete amount and med_from_db key to compare change
        let org = cloneDeep(orderData?.order_details?.items);
        let sel = cloneDeep(selectedMedicines);

        org = org.map((x: any) => {
          delete x.amount;
          delete x.med_from_db;
          delete x.alt_from_pharmacy;
          delete x.requested_item;
          delete x.HSNCode;
          delete x.gst;
          return { ...x, change_type: x.change_type ? x.change_type : "new" };
        });

        //new or available is treated as new to compare, as meds orignially added will have change type new
        sel = sel.map((x: any) => {
          delete x.amount;
          delete x.med_from_db;
          delete x.alt_from_pharmacy;
          delete x.requested_item;
          delete x.HSNCode;
          delete x.gst;
          return {
            ...x,
            change_type:
              !x.change_type ||
              x.change_type === "new" ||
              x.change_type === "available"
                ? "new"
                : x.change_type,
          };
        });

        if (!isArrayEqual(org, sel)) {
          setChangeType("medicine_changed");
        }
        // PARTNER SPECIFIC: LOMBARD AND EXPRESS DELIVERY
        if (
          orderData?.order_partner === "lombard" ||
          orderData?.express_delivery === "y"
        ) {
          setChangeType("");
        }
      }
    }, [values]);
    return null;
  };

  const SubmitButtonComponent = ({ isSubmitting }: any) => {
    return (
      <Box textAlign="center" sx={{ mt: 3 }}>
        <LoadingButton
          size="medium"
          color="primary"
          loading={loading}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          {orderData?.order_status !== ORDER_STATUS.PAYMENT_COLLECTED
            ? changeType === ""
              ? orderData?.order_type === ORDER_TYPE.MEDICINES
                ? "Accept"
                : "Update & Accept"
              : "Submit for confirmation"
            : "Re-generate Invoice"}
        </LoadingButton>
      </Box>
    );
  };

  return (
    <Dialog
      open={editOrderModal}
      onClose={() => setEditOrderModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={orderData?.order_type === ORDER_TYPE.MEDICINES ? "xl" : "lg"}
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setEditOrderModal(false)}
      >
        Edit Order Details
      </CustomDialogTitle>
      <DialogContent>
        <>
          <Formik
            initialValues={{
              provider_id: orderData?.order_partner,
              partner_order_id: orderData?.partner_order_id,
              payment_collection: orderData?.payment_collection,
              consultation_type: orderData?.consultation_type,
              lab_visit_type: orderData?.sample_collection,
              order_type: orderData?.order_type,
              full_name: orderData?.customer_details?.name,
              gender: orderData?.customer_details?.gender,
              age: orderData?.customer_details?.age,
              mobile: orderData?.customer_details?.mobile,
              alt_mobile: orderData?.customer_details?.alternative_mobile,
              email: orderData?.customer_details?.email,
              address: orderData?.customer_details?.address_details?.address,
              landmark: orderData?.customer_details?.address_details?.landmark,
              pin: orderData?.customer_details?.address_details?.pin_code,
              city: orderData?.customer_details?.address_details?.city,
              state: orderData?.customer_details?.address_details?.state,
              speciality: String(orderData?.specialty)?.toLowerCase(),
              time_slot: parseTimeslotToDateObj(orderData?.time_slot),
              time_slot_raw: orderData?.time_slot,
              doctor_name:
                orderData.order_type === ORDER_TYPE.CONSULTATION
                  ? orderData?.merchant_details?.profile_details?.full_name
                  : "",
              doctor_gender:
                orderData.order_type === ORDER_TYPE.CONSULTATION
                  ? String(
                      orderData?.merchant_details?.profile_details?.gender
                    )?.toLowerCase()
                  : "",
              doctor_exp_yrs:
                orderData.order_type === ORDER_TYPE.CONSULTATION
                  ? orderData?.merchant_details?.profile_details
                      ?.experience_in_years
                  : "",
              doctor_degrees:
                orderData.order_type === ORDER_TYPE.CONSULTATION
                  ? orderData?.merchant_details?.profile_details?.degrees
                  : "",
              doctor_mobile:
                orderData.order_type === ORDER_TYPE.CONSULTATION
                  ? orderData?.merchant_details?.profile_details?.mobile_no
                    ? orderData.merchant_details.profile_details?.mobile_no
                    : orderData.merchant_details.profile_details
                        ?.whatsapp_number
                  : "",
              doctor_fee:
                orderData.order_type === ORDER_TYPE.CONSULTATION
                  ? orderData?.merchant_details?.profile_details
                      ?.consultation_fees
                  : "",
              partner_charges:
                orderData?.order_type === ORDER_TYPE.CONSULTATION
                  ? orderData?.partner_consultation_charges
                  : "",
              lab_name: "",
              lab_mobile: "",
              discount:
                orderData?.order_type === ORDER_TYPE.CONSULTATION &&
                orderData?.order_status === ORDER_STATUS.BOOKING_CONFIRMED
                  ? orderData.invoice_details?.discount_pct
                  : "",
              final_payable: "",
              prescribed_by: ORDER_TYPE.MEDICINES
                ? orderData?.order_details?.doctor
                : "",
              delivery_partner:
                orderData?.order_type === ORDER_TYPE.MEDICINES
                  ? orderData?.delivery_partner
                  : "",
              delivery_charges:
                orderData?.order_type === ORDER_TYPE.MEDICINES
                  ? orderData?.delivery_charges
                  : "",
              gst_no: "",
              company: "",
              merchant: "" || undefined,
              merchant_mobile: "",
            }}
            validationSchema={
              orderData?.order_type === ORDER_TYPE.CONSULTATION
                ? editConsultationSchema
                : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                ? editDiagnosticSchema
                : editPharmacySchema
            }
            onSubmit={(values: any, actions: any) => {
              actions.setSubmitting(false);
              handleSubmit(values, actions);
            }}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setTouched,
              } = props;
              return (
                <Form>
                  <FormObserver />
                  <Typography variant="h4" sx={{ mt: 3 }}>
                    Order Details:
                  </Typography>
                  <OrderDetail
                    createOrderType={orderData?.order_type}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    disableField={true}
                    formData={values}
                  />
                  <Typography variant="h4" sx={{ mt: 3 }}>
                    Patient Details:
                  </Typography>
                  <Stack direction="row">
                    <TextField
                      fullWidth
                      id="full_name"
                      label="Full Name"
                      size="small"
                      value={values.full_name || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                    {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                      <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        size="small"
                        value={values.email || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        disabled
                      />
                    )}
                  </Stack>
                  <Typography variant="h4" sx={{ mt: 3 }}>
                    Address Details:
                  </Typography>
                  <AddressDetail
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    disableField={true}
                    formData={values}
                    setTouched={setTouched}
                  />
                  {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                    <EditConsultationOrder
                      setFieldValue={setFieldValue}
                      touched={touched}
                      selectedClinics={selectedClinics}
                      setSelectedClinics={setSelectedClinics}
                      nearbyClinicList={nearbyClinicList}
                      setNearbyClinicList={setNearbyClinicList}
                      errors={errors}
                      handleBlur={handleBlur}
                      orderData={orderData}
                      formData={values}
                      setChangedClinic={setChangedClinic}
                    />
                  )}
                  {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS && (
                    <EditDiagnosticsOrder
                      merchant_details={orderData?.merchant_details}
                      orderType={orderData?.order_type}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      selectedTests={selectedTests}
                      setSelectedTests={setSelectedTests}
                      formData={values}
                      testsTableError={testsTableError}
                      setTestsTableError={setTestsTableError}
                    />
                  )}
                  {orderData?.order_type === ORDER_TYPE.MEDICINES && (
                    <EditMedicinesOrder
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      nearbyPharmacyList={nearbyPharmacyList}
                      selectedPharmacy={selectedPharmacy}
                      setSelectedPharmacy={setSelectedPharmacy}
                      selectedMedicines={selectedMedicines}
                      setSelectedMedicines={setSelectedMedicines}
                      selectedMedicinesId={selectedMedicinesId}
                      setSelectedMedicinesId={setSelectedMedicinesId}
                      orderData={orderData}
                      formData={values}
                      medTableError={medTableError}
                      setMedTableError={setMedTableError}
                    />
                  )}

                  {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                    <>
                      {values.consultation_type === CONSULTATION_TYPE.ONLINE &&
                      values.partner_charges === "" ? (
                        <Box mt={5}>
                          <Typography variant="h4" color="error">
                            Note :{" "}
                          </Typography>
                          <ol type="1" style={{ paddingLeft: "1rem" }}>
                            <li style={{ color: "#d32f2f" }}>
                              Partner consultation charge is not available
                            </li>
                          </ol>
                        </Box>
                      ) : (
                        <Box mt={5}>
                          <Typography variant="h4">Note : </Typography>
                          <ol type="1" style={{ paddingLeft: "1rem" }}>
                            <li>
                              Please check clinic accept Payment via UPI or QR
                            </li>
                            <li>
                              Ask to share QR code on WhatsApp to accept payment
                            </li>
                            <li>
                              WhatsApp no. is required to send patient and
                              booking information
                            </li>
                          </ol>
                        </Box>
                      )}

                      {orderData?.consultation_type ===
                        CONSULTATION_TYPE.ONLINE &&
                      values?.partner_charges === "" ? null : (
                        <SubmitButtonComponent isSubmitting={isSubmitting} />
                      )}
                    </>
                  )}

                  {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS && (
                    <>
                      <Box mt={5}>
                        <Typography variant="h4">Note : </Typography>
                        <ol type="1" style={{ paddingLeft: "1rem" }}>
                          <li>
                            Please check lab is accepting Payment via UPI or QR
                          </li>
                          <li>
                            Ask to share QR code on WhatsApp to accept payment
                          </li>
                          <li>
                            WhatsApp no. is required to send patient and booking
                            information
                          </li>
                        </ol>
                      </Box>
                      <SubmitButtonComponent isSubmitting={isSubmitting} />
                    </>
                  )}

                  {orderData?.order_type === ORDER_TYPE.MEDICINES && (
                    <>
                      {providerFillData && providerFillData === "true" ? (
                        <SelfOptNotes
                          flag={providerFillData}
                          type="mrp"
                          orgTime={parse(
                            orderData.provider_assigned_at?.split(".")[0],
                            "dd/MM/yyyy HH:mm:ss",
                            new Date()
                          )}
                          actionTaken={
                            orderData.merchant_updated_meta &&
                            orderData.merchant_updated_meta.length > 0
                          }
                        />
                      ) : orderData?.order_partner === "lombard" ? (
                        <Stack marginTop={10}>
                          <Typography variant="h4">Note : </Typography>
                          <Typography variant="body1">
                            Please add alternative for non-mapped medicines,
                            where price and quantity cannot be change.
                          </Typography>
                        </Stack>
                      ) : null}

                      {providerFillData &&
                      providerFillData === "true" ? null : (
                        <>
                          {selectedMedicines?.every(
                            (x: any) => x?.change_type === "not_available"
                          ) ? (
                            <Stack marginTop={5}>
                              <Typography variant="h4">Note : </Typography>
                              <Typography variant="body1">
                                Please cancel this order if none of the above
                                items are available.
                              </Typography>
                            </Stack>
                          ) : (
                            <SubmitButtonComponent
                              isSubmitting={isSubmitting}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default EditOrderModal;
